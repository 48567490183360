/**
 * Color name are taken from src/styles/global/_color.scss,
 * while rest of colors which are not present will use from link below:
 * Its a simple tool to generate color names:  https://color-name-generator.com/
 * */

export const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  SECONDARY: '#5d6572',
  DISABLED: '#d9d9d9', // also used as a border color too
  LOYALTY: '#E0E0E0',
  DANGER: '#E65359',
  ATTENTION: '#eb6464',
  GREEN_PRIMARY: '#169a89',
  GREY_BACKGROUND: '#f8f9f8',
  GREY_PRIMARY: '#f4f4f4',
  GREY_LIGHT: '#e2e2e2',
  BLUE_DARK: '#2381c0',
  TRANSPARENT: 'transparent',
  VIOLET: '#ac43bf',
  GOLDEN_YELLOW: '#FFD503',
  EDIT_YELLOW: '#f2b673',
  BLUE_LIGHT: '#7297ee',
  YELLOW_GREEN: '#8fcf3b',
  // below are the names take from color-name-generator.com
  LIGHT_BLUE: '#859198',
  ICONIC_SKY: '#d1edeb',
  CHAIR_PINK: '#bf3b8c',
};
