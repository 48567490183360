import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PartnerContext } from 'contexts/PartnerProvider';
import { PUBLIC_ROUTES } from 'constants/routes';
import { APPLE_REVIEW_PARTNER_ID } from 'constants/app-env';
import { open360BillingSettings } from 'util/url';
import BlockedAccess from './BlockedAccess';
import AlertsContainer from 'components/AlertsContainer';

const PaymentProfile = ({ showBackButton }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    partner: { partnerId },
    selectVenuePaymentProfiles,
    selectIsVenueInPaymentWarning,
    selectIsVenueAccessBlocked,
  } = useContext(PartnerContext);
  const venuePaymentProfile = selectVenuePaymentProfiles();
  const isVenueInPaymentWarning = selectIsVenueInPaymentWarning();
  const isVenueAccessBlocked = selectIsVenueAccessBlocked();
  const { setVisiblePaymentWarning } = useContext(PartnerContext);

  const hidePaymentProfile = () => {
    setVisiblePaymentWarning(false);
  };

  const handleBillingPage = () => {
    open360BillingSettings();
  };

  // we need to hide everything related to payment for apple review account for ipad app.
  if (partnerId === APPLE_REVIEW_PARTNER_ID) {
    return null;
  }

  if (PUBLIC_ROUTES.includes(location.pathname)) {
    return null;
  }

  if (isVenueAccessBlocked) {
    return (
      <BlockedAccess
        handleBillingPage={handleBillingPage}
        isVenueAccessBlocked={isVenueAccessBlocked}
        showBackButton={showBackButton}
      />
    );
  }

  const paymentProfileAlerts = [
    {
      id: 'venue-payment-warning',
      messages: venuePaymentProfile?.warnings,
      buttonLabel: t('payment-profile.go-to-billing'),
      buttonAction: handleBillingPage,
      closeAction: hidePaymentProfile,
      isVisible: isVenueInPaymentWarning,
    },
  ];

  return <AlertsContainer paymentProfileAlerts={paymentProfileAlerts} />;
};

PaymentProfile.propTypes = {
  showBackButton: PropTypes.bool,
};

export default PaymentProfile;
