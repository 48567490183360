import { authEndpoints, partnerEndpoints, paymentProfileEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';

export const getPartnerDetails = async (token) => {
  const url = partnerEndpoints.partnerDetailsGET();
  const defaultAuthHeaders = getDefaultAuthHeaders(token);

  const response = await request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });

  return response;
};

export const authPartner = async ({ username, password } = {}) => {
  const url = authEndpoints.loginPOST();
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
      grant_type: 'password',
    }),
  });

  return response;
};

export const validateToken = async ({ token } = {}) => {
  const url = authEndpoints.tokenGET();
  const defaultAuthHeaders = getDefaultAuthHeaders(token);
  const response = await request(url, {
    method: 'GET',
    headers: { ...defaultAuthHeaders },
  });

  return response;
};

export const getPaymentProfiles = (token) => {
  const url = paymentProfileEndpoints.fetchPaymentProfiles();
  const defaultAuthHeaders = getDefaultAuthHeaders(token);
  return request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });
};

export const fetchCurrentUser = async (token) => {
  const url = partnerEndpoints.currentUserGET();
  const defaultAuthHeaders = getDefaultAuthHeaders(token);

  const response = await request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });

  return response.user;
};

export const fetchReferralRewards = async (token, countryIsoCode) => {
  const url = partnerEndpoints.referralRewardsGET(countryIsoCode);
  const defaultAuthHeaders = getDefaultAuthHeaders(token);

  const response = await request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });

  return response.rewards;
};
