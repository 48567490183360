import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { HELP_CHAT_INTERCOM_ID } from 'constants/app-const';
import { openHelpCenterLink } from 'util/url';
import { sentryFeedbackDialog } from 'util/sentry';
import { ReactComponent as HelpIcon } from 'images/help-icon.svg';
import helpPopoverIcon from 'images/help-popover-icon.svg';
import chatIcon from 'images/chat-popover-icon.svg';
import { useReferral } from 'hooks/useReferral';
import AlertIcon from 'images/icons/AlertIcon';
import AddPersonIcon from 'components/Icons/AddPersonIcon';
import * as styles from './HelpPopover.styles';

const HelpPopover = ({ colored = false }) => {
  const [t] = useTranslation();
  const [showPopover, setShowPopover] = useState(false);
  const { openReferralLink } = useReferral();

  return (
    <Popup
      open={showPopover}
      onOpenChange={(v) => {
        setShowPopover(v);
      }}
      placement="topRight"
      triggerElement={
        <div css={styles.helpButton({ colored })}>
          <HelpIcon />
          <span>{t('help')}</span>
        </div>
      }
    >
      <div css={styles.helpPopover}>
        <button
          type="button"
          className="help-popover-item"
          onClick={() => {
            openReferralLink();
          }}
        >
          <AddPersonIcon width={50} height={50} />
          <span>{t('refer-friends-get-rewarded')}</span>
        </button>
        <button
          type="button"
          className="help-popover-item"
          onClick={() => {
            openHelpCenterLink();
          }}
        >
          <img src={helpPopoverIcon} alt="help-center" />
          <span>{t('help-center')}</span>
        </button>
        <button type="button" className="help-popover-item" id={HELP_CHAT_INTERCOM_ID}>
          <img src={chatIcon} alt="chat" />
          <span>{t('chat-with-us')}</span>
        </button>
        {sentryFeedbackDialog && (
          <button
            type="button"
            className="help-popover-item"
            onClick={() => {
              sentryFeedbackDialog.open();
            }}
          >
            <AlertIcon width={30} height={30} />
            <span>Leave feedback</span>
          </button>
        )}
      </div>
    </Popup>
  );
};

HelpPopover.propTypes = {
  colored: PropTypes.bool,
};

export default HelpPopover;
