import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/Close';
import styles from './AlertsContainer.module.scss';

const AlertButtons = ({ buttonLabel, onButtonClick, closeLabel, onClose, isReferral }) => (
  <>
    <div
      className={classnames(styles.actionButtonContainer, {
        [styles.referralButtonContainer]: isReferral,
      })}
    >
      {isReferral ? (
        <button type="button" onClick={onClose} className={styles.actionButtonNoThanks}>
          {closeLabel}
        </button>
      ) : null}
      <button
        type="button"
        className={classnames(styles.actionButton, { [styles.referralBtn]: isReferral })}
        onClick={onButtonClick}
      >
        {buttonLabel}
      </button>
    </div>
    {!isReferral ? <CloseIcon className={styles.closeIcon} onClick={onClose} /> : null}
  </>
);

AlertButtons.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeLabel: PropTypes.string.isRequired,
  isReferral: PropTypes.bool,
};

export default AlertButtons;
