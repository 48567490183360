import { useContext, useEffect } from 'react';
import {
  getDataFromStorage,
  getReferralRewardAndLink,
  isMobileDevice,
  setDataToStorage,
} from '@umai/common';
import { PartnerContext } from 'contexts/PartnerProvider';

export const useReferral = () => {
  const {
    partner: {
      isAuthenticated,
      isLoyaltyProgramEnabled,
      currentVenueId,
      username,
      country,
      defaultLanguage,
      referralReward,
    },
    selectIsVenueAccessBlocked,
    setReferralVisibility,
  } = useContext(PartnerContext);
  const isVenueAccessBlocked = selectIsVenueAccessBlocked();

  const { rewardOffer, openReferralLink } = getReferralRewardAndLink({
    venueId: currentVenueId,
    email: username,
    amount: referralReward?.amount || '',
    currency: referralReward?.currency || '',
    countryIsoCode: country?.countryIsoCode || '',
    defaultLanguage: defaultLanguage?.language || '',
  });

  const REFERRAL_BANNER_LAST_OPENED = 'referralBannerLastOpened';
  const REFERRAL_POPUP_LAST_OPENED = 'referralPopupLastOpened';

  const handleCloseBanner = () => {
    setDataToStorage(REFERRAL_BANNER_LAST_OPENED, String(Date.now()));
    setReferralVisibility({ isBannerVisible: false });
  };

  const handleClosePopup = () => {
    setDataToStorage(REFERRAL_POPUP_LAST_OPENED, String(Date.now()));
    setReferralVisibility({ isPopupVisible: false });
  };

  const handleReferral = () => {
    openReferralLink();
    handleCloseBanner();
    handleClosePopup();
  };

  useEffect(() => {
    const ONE_MONTH_MS = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    const TWO_MONTHS_MS = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds
    const THREE_MONTHS_MS = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds

    const referralBannerDisplayInterval = isMobileDevice ? TWO_MONTHS_MS : ONE_MONTH_MS;
    const referralBannerLastOpened = getDataFromStorage(REFERRAL_BANNER_LAST_OPENED);
    const referralPopupLastOpened = getDataFromStorage(REFERRAL_POPUP_LAST_OPENED);
    const currentTime = Date.now();

    if (isAuthenticated && isLoyaltyProgramEnabled && rewardOffer && !isVenueAccessBlocked) {
      // Show popup if it's been more than 3 months since last opened
      if (currentTime - Number(referralPopupLastOpened) > THREE_MONTHS_MS) {
        setReferralVisibility({ isPopupVisible: true });
      }

      // Show banner if it's been more than the designated duration (1 month for non-mobile, 2 months for mobile)
      if (currentTime - Number(referralBannerLastOpened) > referralBannerDisplayInterval) {
        setReferralVisibility({ isBannerVisible: true });
      }
    }
  }, [
    setReferralVisibility,
    isAuthenticated,
    isLoyaltyProgramEnabled,
    rewardOffer,
    isVenueAccessBlocked,
  ]);

  return {
    handleCloseBanner,
    handleReferral,
    handleClosePopup,
    openReferralLink,
    rewardOffer,
  };
};
