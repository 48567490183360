import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from './Alert';
import { PartnerContext } from 'contexts/PartnerProvider';
import { useReferral } from 'hooks/useReferral';
import ReferralBanner from 'components/Referral/ReferralBanner';
import styles from './AlertsContainer.module.scss';

// NOTE: this class name should be the same as in the @mixin is-venue-in-payment-warning
const venueInPaymentWarningClass = 'venue-in-payment-warning';

function AlertsContainer({ paymentProfileAlerts }) {
  const { t } = useTranslation();
  const alertRef = useRef();
  const {
    partner: { isReferralBannerVisible },
  } = useContext(PartnerContext);
  const { handleCloseBanner, handleReferral, rewardOffer } = useReferral();
  const referralMessage = renderToStaticMarkup(<ReferralBanner rewardOffer={rewardOffer} />);

  const alerts = [
    {
      id: 'referral-banner',
      messages: [`${referralMessage}`],
      buttonLabel: t('learn-more'),
      buttonAction: handleReferral,
      closeLabel: t('no-thanks'),
      closeAction: handleCloseBanner,
      isVisible: isReferralBannerVisible,
      isReferral: true,
    },
    ...paymentProfileAlerts,
  ].filter((alert) => alert.isVisible);

  useLayoutEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      // this will get called whenever div dimension changes
      entries.forEach(() => {
        document.documentElement.style.setProperty(
          '--payment-warning-height',
          `${alertRef.current.offsetHeight}px`
        );
      });
    });

    // observer for height changes when the payment warning resizes, so that we can adjust the screen with warning height
    myObserver.observe(alertRef.current);

    return () => {
      //  stop listening to changes
      myObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (alerts.length > 0) {
      bodyClassList.add(venueInPaymentWarningClass);
    } else {
      bodyClassList.remove(venueInPaymentWarningClass);
    }

    return () => {
      bodyClassList.remove(venueInPaymentWarningClass);
    };
  }, [alerts.length]);

  return (
    <div ref={alertRef} role="alert" className={styles.alertsContainer}>
      {alerts?.map(
        ({ id, closeLabel, closeAction, buttonLabel, buttonAction, messages, isReferral }) => (
          <Alert
            key={id}
            closeLabel={closeLabel}
            onClose={closeAction}
            buttonLabel={buttonLabel}
            onButtonClick={buttonAction}
            messages={messages}
            isReferral={isReferral}
          />
        )
      )}
    </div>
  );
}

AlertsContainer.propTypes = {
  paymentProfileAlerts: PropTypes.arrayOf(PropTypes.object),
};

export default AlertsContainer;
