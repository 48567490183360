import { css } from '@emotion/react';

export const helpPopover = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 0.25rem;

  .help-popover-item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #000;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #d9d9d9;
    background: transparent;
    text-align: left;

    span {
      padding: 1rem 0;
    }

    svg,
    img {
      margin: 0 1rem;
      color: #5d6572;
      margin-bottom: 0;
      vertical-align: middle;
    }

    &:hover {
      text-decoration: underline;
    }

    :last-child {
      border-bottom: none;
    }
  }
`;

export const helpButton = ({ colored }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  color: ${colored ? 'var(--color-primary)' : '#fff'};
  &:hover {
    ${!colored && 'color: var(--color-primary)'};
    ${!colored &&
    `svg {
      path {
        fill: var(--color-primary) !important;
      }
    }
    `};
  }

  svg {
    margin-right: 0.5rem;
    margin-bottom: 0;

    path {
      fill: ${colored ? 'var(--color-primary)' : '#fff'};
    }
  }
`;
