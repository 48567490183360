import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useReferral } from 'hooks/useReferral';
import { PartnerContext } from 'contexts/PartnerProvider';
import ModalWrapper from 'components/ModalWrapper';
import styles from './Referral.module.scss';

const ReferralPopup = () => {
  const { t } = useTranslation();
  const {
    partner: { isReferralPopupVisible },
  } = useContext(PartnerContext);

  const { handleClosePopup, handleReferral, rewardOffer } = useReferral();

  return (
    <ModalWrapper
      modalIsOpen={isReferralPopupVisible}
      resetModalHandler={handleClosePopup}
      className={styles.modal}
    >
      <section className={styles.popupContainer}>
        <h3 className={styles.title}>{t('refer-friends-get-rewarded')}</h3>
        <img
          src={`${process.env.PUBLIC_URL}/restaurant-meeting.jpg`}
          alt="Restaurant meeting"
          className={styles.popupImage}
        />
        <h4 className={styles.reward}>{t('earn-up-to-per-referral', { rewardOffer })}</h4>
        <p className={styles.text}>
          {t('the-reward-can-go-to-you-as-an-individual-or-to-the-restaurant')}
        </p>
        <div className={styles.buttonsWrapper}>
          <button type="button" className={styles.successBtn} onClick={handleReferral}>
            {t('learn-more')}
          </button>
          <button
            type="button"
            onClick={handleClosePopup}
            className={classNames(styles.successBtn, styles.btnOutlineDark)}
          >
            {t('no-thank-you')}
          </button>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default ReferralPopup;
