import { assignEventListener } from 'util/event-helper';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'react-modal';
import './ModalWrapper.scss';

const ModalWrapper = ({
  children,
  modalIsOpen,
  wrapperSelector = 'body',
  resetModalHandler,
  small = false,
  scrollableArea = 'wrapper',
  useMouseUpListener = false,
  hideClose,
  id,
  shouldCloseOnEsc = false,
  shouldCloseOnOverlayClick = false,
  portalClassName,
  className,
  overlayRef,
}) => {
  const [isOpen, setOpen] = useState(false);

  // open modal on mount
  useEffect(() => {
    Modal.setAppElement(wrapperSelector);
    setOpen(true);
    // eslint-disable-next-line
  }, []);

  const renderChildren = () => {
    if (scrollableArea !== 'body') {
      return children;
    }
    return <div className="ReactModal__customBody">{children}</div>;
  };

  const renderButton = () => {
    if (useMouseUpListener) {
      return (
        <button
          id="close-modal-button"
          className="ReactModal__closeButton"
          type="button"
          onMouseUp={resetModalHandler}
        />
      );
    }
    return (
      <button
        id="close-modal-button"
        className="ReactModal__closeButton"
        type="button"
        {...assignEventListener(resetModalHandler)}
      />
    );
  };

  const closeModal = () => {
    if (hideClose) return;
    setOpen(false);
  };

  let customClasses = `ReactModal__customContent${small ? 'Small' : ''}`;
  if (scrollableArea === 'body') customClasses += ` ScrollableBody`;
  return (
    <div className="modal-wrapper">
      <Modal
        id={id}
        isOpen={modalIsOpen ?? isOpen}
        onRequestClose={closeModal}
        closeModal={closeModal}
        className={classnames(className, customClasses)}
        overlayClassName="ReactModal__customOverlay"
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        closeTimeoutMS={200}
        overlayRef={overlayRef}
        portalClassName={portalClassName}
      >
        {!hideClose && renderButton()}
        {renderChildren()}
      </Modal>
    </div>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  modalIsOpen: PropTypes.bool,
  resetModalHandler: PropTypes.func,
  wrapperSelector: PropTypes.string,
  small: PropTypes.bool,
  scrollableArea: PropTypes.string,
  useMouseUpListener: PropTypes.bool,
  hideClose: PropTypes.bool,
  id: PropTypes.string,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  portalClassName: PropTypes.string,
  className: PropTypes.string,
  overlayRef: PropTypes.func,
};

export default ModalWrapper;
