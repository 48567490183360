import React from 'react';
import PropTypes from 'prop-types';
import AddPersonIcon from 'components/Icons/AddPersonIcon';
import styles from './Referral.module.scss';
import { useTranslation } from 'react-i18next';

const ReferralBanner = ({ rewardOffer }) => {
  const { t } = useTranslation();

  return (
    <span className={styles.referralBanner}>
      <AddPersonIcon />
      {t('refer-a-friend-earn-up-to-per-referral', { rewardOffer })}
    </span>
  );
};

ReferralBanner.propTypes = {
  rewardOffer: PropTypes.string.isRequired,
};

export default ReferralBanner;
